import Login from '../pages/login';
import Landing from '../pages/landing';
import Document from '../pages/document';
import Products from '../pages/products';
import ViewClient from '../pages/viewClient';
import Inbox from '../pages/inbox';
import InboxDetails from '../pages/inboxDetails';
import Investments from '../pages/investments';
import ActivateAccount from '../pages/activateAccount'
import forget from '../pages/forget'
import LoginInformation from '../pages/loginInformation'
import home from '../pages/home'
import Profile from '../pages/profile'
import TandC from '../pages/TandC'
import PrivacyPolicy from '../pages/privacyPolicy'
import ContactUs from '../pages/contactUs'
import loginTandC from '../pages/loginTandC'
import ExitVoting from '../pages/exitVoting'
import TaxQuestionnaire from '../pages/taxQuestionnaire'

export const ROUTER_TABLE = [
  {
    path: '/',
    component: Landing,
    auth: true,
  },
  {
    path: '/document',
    component: Document,

  },
  {
    path: '/products',
    component: Products,
    auth: true,

  },
  {
    path: '/viewClient',
    component: ViewClient,
    auth: true,

  },
  {
    path: '/landing',
    component: Landing,
    auth: true,
  },
  {
    path: '/inbox',
    component: Inbox,
    auth: true,
  },
  {
    path: '/inboxDetails',
    component: InboxDetails,
    auth: true,
  },
  {
    path: '/inboxDetails/:id',
    component: InboxDetails,
    auth: true,
  },
  {
    path: '/investments/:id/:name',
    component: Investments,
    auth: true,
  },
  {
    path: '/investments',
    component: Investments,
    auth: true
  },
  {
    path: '/activateAccount',
    component: ActivateAccount,
    auth: true
  },
  {
    path: '/loginInformation',
    component: LoginInformation,
    auth: true
  },
  {
    path: '/loginTandC',
    component: loginTandC,
    auth: true 
  },
  {
    path: '/profile',
    component: Profile,
    auth: true
  },
  {
    path: '/TandC',
    component: TandC,
    auth: true
  },
  {
    path: '/PrivacyPolicy',
    component: PrivacyPolicy,
    auth: true
  },
  {
    path: '/contactUs',
    component: ContactUs,
    auth: true
  },
  {
    path: '/home',
    component: home,
    auth: true
  },
  {
    path: '/forget',
    component: forget,
    auth: true
  },
  {
    path: '/exitvoting',
    component: ExitVoting,
    auth: true
  }
  // ,
  // {
  //   path: '/taxQuestionnaire',
  //   component: TaxQuestionnaire,
  //   auth: true
  // }
  
];
