import './index.scss';
import DOMPurify from 'dompurify';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default KYC; function KYC({ Template }) {
    const history = useHistory();
    const { t } = useTranslation();
    function goDetail(url) {
        history.push({
            pathname: url
        });
    }

    const pageBreak = Template.split('#PAGE_BREAK:');
    const index = pageBreak.findIndex(item => item.includes('KYC#')) - 1;

    let template = pageBreak[index].replace(pageBreak[index].split('#')[0].trim() + '#', '');
    if (template.includes('</head>')) {
        template = template.split('</head>')[1];
    }

    const kyc = DOMPurify.sanitize('<html>' + template + '</html>');

    return (
        <>
            <div id="kyc-div">
                <div className='row stepper-title'>
                    <p>
                        {t('KYC')}
                    </p>
                    <button className='update-profile-button' onClick={() => { goDetail('/profile') }}>{t('UPDATE PROFILE')}</button>
                </div>
                <div className='row stepper-p kyc-content'>
                    <div dangerouslySetInnerHTML={{ __html: kyc }} />
                </div>
            </div>
        </>
    );
}