import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { error, updateError, updateUserInfo } from '../../redux/slice';
import store from '../../redux/store';
const baseurl = process.env.REACT_APP_BASEPATH;


const ErrorDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errorObj = useSelector(error);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    if (isTimeout) {
      logout();
      setIsTimeout(false);
    }
    dispatch(updateError({ errorCode: '', isShow: false }));
  };
  const logout = async () => {
    dispatch(updateUserInfo({}));
    window.location.href = baseurl + '/auth/aad/b2c/logout';
  };
  const exceptionList = [
    // { code: 1001, message: 'INVALID_PARAMETER', ignore: true },
    { code: 1001, message: 'INVALID_PARAMETER', ignore: false },
    { code: 1002, message: 'ENCRYPTION_EXCEPTION', ignore: true },
    { code: 1003, title: 'UNEXPECTED_ERROR', message: 'UNEXPECTED_ERROR_desc', ignore: false }, // DECRYPTION_EXCEPTION
    { code: 2002, message: 'BAD_CREDENTIALS', ignore: false },
    { code: 2003, title: "info_not_match", message: 'ac_invalid', ignore: false },
    {
      code: 2005,
      title: 'INVALID_USER_SESSION',
      message: 'INVALID_USER_SESSION_desc',
      ignore: false,
    },
    { code: 2006, message: 'PASSWORD_FORMAT_CORRECT', ignore: true },
    {
      code: 2007,
      title: 'INVALID_USER_SESSION_LOGIN_AGAIN',
      message: 'INVALID_USER_SESSION_LOGIN_AGAIN_desc',
      ignore: false,
    },
    { code: 2012, title: 'USER_NAME_EXIST', message: 'USER_NAME_EXIST_desc', ignore: false },
    { code: 2015, title: 'USER_IS_INACTIVE', message: 'USER_IS_INACTIVE_desc', ignore: false },
    { code: 2016, message: 'USER_IS_SUSPEND', ignore: true },
    { code: 2017, title: 'USER_IS_DELETED', message: 'USER_IS_DELETED_desc', ignore: false },
    { code: 2018, title: 'LINK_EXPIRED', message: 'LINK_EXPIRED_desc', ignore: false }, // USER_RESET_ERROR
    {
      code: 2019,
      title: 'CLIENTID_VERIFY_EXPIRY',
      message: 'CLIENTID_VERIFY_EXPIRY_desc',
      ignore: false,
    },
    { code: 2020, message: 'CLIENTID_STATUS_ERROR', ignore: false },
    { code: 2021, message: 'EMAIL_FORMAT_CORRECT', ignore: true },
    { code: 2022, message: 'FORGET_USERNAME_MUTI_USER', ignore: false },
    { code: 2023, title: 'LINK_EXPIRED', message: 'LINK_EXPIRED_desc', ignore: false }, // EMAIL_VERIFY_EXPIRY
    // { code: 2024, title: 'USER_STATUS_IS_NOT_OPEND', ignore: true },
    { code: 2024, message: 'USER_STATUS_IS_NOT_OPEND', ignore: false },
    { code: 2025, title: 'USER_BIOMETRIC_INCORRECT', ignore: true },
    { code: 2026, title: 'USER_BIOMETRIC_IS_TURN_OFF', ignore: true },
    { code: 2031, title: 'UNEXPECTED ERROR', message: t('UNEXPECTED_ERROR_desc') ,ignore: false },
    { code: 2032, message: 'USER_HAVE_NOT_PHONE_NUMBER', ignore: false },
    { code: 2033, message: 'USER_HAVE_NOT_EMAIL', ignore: false },
    { code: 2035, message: t('Incorrect Postal'), ignore: false },


    { code: 9001, title: 'UNEXPECTED_ERROR', message: 'UNEXPECTED_ERROR_desc', ignore: false },
    { code: 9002, title: 'TAX QUESTIONNAIRE', message: 'Invalid tax refund payment method.', ignore: false },

    // FE custom error code
    {
      code: 9998,
      title: 'NO_NETWORK_CONNECTION',
      message: 'NO_NETWORK_CONNECTION_desc',
      ignore: false,
    },


    {
      code: 99999,
      message: 'Please accept the Terms & Conditions.',
      ignore: false,
    },
  ];
  useEffect(() => {
    console.log(errorObj.isShow);
    if (errorObj.isShow) {
      let item = {};
      for (item of exceptionList) {
        if (errorObj.errorCode == item.code && !item.ignore) {
          setErrorMessage(item.message);
        }
      }
      setIsModalOpen(true);
      if (errorObj.errorCode == 2005 || errorObj.errorCode == 2007) {
        setIsTimeout(true);
      }
    }
  }, [errorObj]);
  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
        width={400}
        classNames={{ body: 'custom-modal-body' }}
      >
        <div>
          <div className="custom-modal-body">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.6474 14.9214C31.2204 10.3831 35.0054 7.5 39.9993 7.5C44.9931 7.5 48.7782 10.3831 52.3512 14.9214C55.8652 19.3849 59.5892 25.9876 64.3538 34.4355L65.8052 37.0089C69.7439 43.9921 72.8547 49.5076 74.4887 53.9346C76.1586 58.4587 76.5553 62.5664 74.0292 66.1898C71.578 69.7059 67.4882 71.1446 62.3298 71.8236C57.1912 72.5001 50.2809 72.5 41.4153 72.5H38.5833C29.7177 72.5 22.8073 72.5001 17.6687 71.8236C12.5104 71.1446 8.42057 69.7059 5.96931 66.1898C3.44328 62.5664 3.83996 58.4587 5.5098 53.9346C7.14381 49.5076 10.2546 43.9922 14.1933 37.009L15.6447 34.4356C20.4093 25.9877 24.1333 19.3849 27.6474 14.9214ZM31.5759 18.0144C28.3292 22.1383 24.7895 28.3996 19.8825 37.0998L18.6703 39.2491C14.5829 46.4961 11.6783 51.6622 10.2005 55.6659C8.74168 59.6182 8.94394 61.7137 10.071 63.3303C11.2727 65.0542 13.5284 66.2355 18.3213 66.8664C23.0946 67.4948 29.6747 67.5 38.7871 67.5H41.2115C50.3238 67.5 56.9039 67.4948 61.6772 66.8664C66.4701 66.2355 68.7258 65.0542 69.9276 63.3303C71.0546 61.7137 71.2569 59.6182 69.7981 55.6659C68.3203 51.6621 65.4156 46.4961 61.3282 39.249L60.116 37.0998C55.209 28.3996 51.6693 22.1383 48.4226 18.0144C45.2061 13.9288 42.7079 12.5 39.9993 12.5C37.2906 12.5 34.7925 13.9288 31.5759 18.0144Z"
                fill="#E3401E"
              />
              <path
                d="M39.9993 24.1665C41.3801 24.1665 42.4994 25.2858 42.4994 26.6665V43.3332C42.4994 44.7139 41.3801 45.8332 39.9993 45.8332C38.6186 45.8332 37.4993 44.7139 37.4993 43.3332V26.6665C37.4993 25.2858 38.6186 24.1665 39.9993 24.1665Z"
                fill="#E3401E"
              />
              <path
                d="M39.9993 56.6665C41.8403 56.6665 43.3327 55.1741 43.3327 53.3332C43.3327 51.4922 41.8403 49.9998 39.9993 49.9998C38.1584 49.9998 36.666 51.4922 36.666 53.3332C36.666 55.1741 38.1584 56.6665 39.9993 56.6665Z"
                fill="#E3401E"
              />
            </svg>
          </div>

          <div className="custom-modal-body" style={{ fontSize: '22px', color: '#E3401E' }}>
            ERROR
          </div>
          <div>
            <div
              className="custom-modal-body"
              style={{ fontSize: '16px', color: '#003448', textAlign: 'center', flexDirection: 'column', fontWeight: 'bold' }}
              dangerouslySetInnerHTML={{ __html: t(errorMessage) }}
            >
            </div>
            {/* {t(errorMessage)} */}
          </div>
          <div className="custom-modal-body">
            <button
              type="button"
              class="nextBtn"
              onClick={() => {
                handleCancel();
              }}
            >
              {t('Done')}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
const ErrorHandle = (errorCode) => {
  let errorObj = { errorCode: errorCode, isShow: true };
  store.dispatch(updateError(errorObj));
  if (errorCode == 2005 || errorCode == 2007) {
    // confirm();
  }
};
export { ErrorDialog, ErrorHandle };
